.outer {
  background-color: #ed8b00;
  padding: 5px;
  position: absolute;
  bottom: 50vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 350px !important;
  height: 93px !important;
  max-width: 45vw;
  border-radius: 50px;
  box-shadow: inset 0pt 0pt 8pt 3pt #c0760d;
  display: inline-flex;
  animation: slideInAndOut 3s 1;
  z-index: 10 !important;
}

.outer:after {
  content: "";
  top: 0;
  transform: translateX(-2000px);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  animation: glare 0.7s 1;
  animation-delay: 0.5s;
  background: gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  );
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
}

.col-inner {
  max-width: 85px !important;
  height: 75px;
  margin: auto 2px auto 17px;
  padding: 0 !important;
}

.inner {
  background-color: #364961;
  position: relative;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background: url("../media/plexxis_logo.jpg");
  background-size: cover;
  background-position: center;
}

.col-text {
  color: white;
  position: relative;
  max-width: 250px !important;
  height: 75px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text p {
  max-width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes glare {
  0% {
    opacity: 0 !important;
    transform: translateX(-6%);
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0 !important;
    transform: translateX(45%);
  }
}

@keyframes slideInAndOut {
  0% {
    transform: translateY(50vh) scale(0.7);
    opacity: 0.7;
  }

  5% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  10% {
    transform: scale(1);
    opacity: 1;
  }

  85% {
    transform: scale(1);
    opacity: 1;
  }

  90% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translateY(-100vh) scale(0.7);
    opacity: 0.7;
  }
}

.stars {
  background: url("../media/stars.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 75px;
  z-index: 20;
}

.super {
  background: url("../media//super.png");
  background-size: cover;
  z-index: 1;
}
