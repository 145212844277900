:root {
  /* Dark Mode */
  --dark-mode-surface: #1d1d1d;
  --dark-mode-surface-font-color: #e3e3e3;
  --dark-mode-surface-primary-hover: #f7dede;
  --dark-mode-surface-secondary-hover: #6bdef5;
  --dark-mode-surface-hover: #292929;
  --dark-mode-surface-focused: #404040;
  --dark-mode-surface-focused-font-color: #ffffff;
  --dark-mode-background: #121212;
  --dark-mode-primary-color: #ffab95;
  --dark-mode-primary-variant: #f04624;
  --dark-mode-secondary-color: #0c6b7e;
  --dark-mode-secondary-focus: rgba(12, 107, 126, 0.33);
  --dark-mode-secondary-variant-color: #24cef0;
  --dark-mode-background-font-color: #cfcfcf;
  --dark-mode-secondary-font-color: #000000;
  --dark-mode-excellent-rating: #008f30;
  --dark-mode-good-rating: #09711b;
  --dark-mode-good-background: #121212;
  --dark-mode-fair-rating: #586808;
  --dark-mode-bad-rating: #bf0d48;
  --dark-mode-bad-rating-background: #121212;
  --dark-mode-error: #cf6679;
  --dark-mode-error-font-color: #000000;
  --dark-mode-disabled-container: #404040;
  --dark-mode-disabled-container-hover: #5c5c5c;
  --dark-mode-disabled-text: #666666;
  --dark-mode-light-theme: #009ad6;
  --dark-mode-dark-theme: #005b80;
  --dark-mode-expectation-dates-no-data: #696969;
  --dark-mode-expectations-date-no-date-font-color: #e3e3e3;
  --dark-mode-primary-message-bg: #1192ac;
  --dark-mode-mentioned-background: #6d4b26;
  --dark-mode-mentioned-font-color: #f2bd33;
  --dark-mode-chart-border: #545454;
  --dark-mode-swimLane-font-color: "#e3e3e3";
  --dark-mode-inset-box-shadow: inset 0px 0px 5px #444;
  --dark-mode-surface-border: 2px solid black;
  --dark-mode-table-header-font-color: #c4c4c0;
  --dark-mode-table-header-background: #04262f;
  --dark-mode-card-background-color: #1d1d1d;

  /* Light Mode */
  --light-mode-surface: #ffffff;
  --light-mode-surface-font-color: #000000;
  --light-mode-surface-primary-hover: #7996c8;
  --light-mode-surface-secondary-hover: #a9e7f4;
  --light-mode-surface-hover: #cfcfcf;
  --light-mode-surface-focused: #404040;
  --light-mode-surface-focused-font-color: #ffffff;
  --light-mode-background: #ffffff;
  --light-mode-primary-color: #5c80bc;
  --light-mode-secondary-color: #0c6b7e;
  --light-mode-secondary-focus: rgba(12, 107, 126, 0.33);
  --light-mode-background-font-color: #4d4d4d;
  --light-mode-secondary-font-color: #ffffff;
  --light-mode-excellent-rating: #00ca44;
  --light-mode-good-rating: #75cd49;
  --light-mode-good-background: #eaf6e4;
  --light-mode-fair-rating: #bdbe37;
  --light-mode-bad-rating: #e4194d;
  --light-mode-bad-rating-background: #f8c4d1;
  --light-mode-error: #b00020;
  --light-mode-error-font-color: #ffffff;
  --light-mode-disabled-container: #f1efef;
  --light-mode-disabled-container-hover: #ffffff;
  --light-mode-disabled-text: #272525;
  --light-mode-light-theme: #009ad6;
  --light-mode-dark-theme: #005b80;
  --light-mode-expectation-dates-no-data: #696969;
  --light-mode-expectations-date-no-date-font-color: #ffffff;
  --light-mode-primary-message-bg: #25b6dc;
  --light-mode-mentioned-background: #fae9b4;
  --light-mode-mentioned-font-color: #126ea6;
  --light-mode-chart-border: #e5e5e5;
  --light-mode-swimLane-font-color: #fff;
  --light-mode-inset-box-shadow: inset 0px 0px 5px #bbb;
  --light-mode-surface-border: unset;
  --light-mode-table-header-font-color: #e6e6e5;
  --light-mode-table-header-background: #04414d;
  --light-mode-card-background-color: #f5f5f5;
}

.reviewButton {
  background-color: rgb(104, 144, 230) !important;
  margin-top: 0.5em !important;
  margin-bottom: -1em !important;
}

.reviewButton:hover {
  background-color: rgba(83, 131, 235, 1) !important;
  color: white !important;
  transition: all 1s ease;
}

#modal {
  max-height: 80vh;
  overflow: auto !important;
  top: 20 !important;
}

@media screen and (max-width: 768px) {
  #modal {
    max-height: 50vh !important;
    overflow: auto !important;
  }
}
@media screen and (max-height: 1100px) {
  #modal {
    max-height: 50vh !important;
    overflow: auto !important;
  }
}
.message {
  display: flex !important;
}
.message p {
  margin: 0;
}
.messageInfo {
  text-indent: 4px;
}
.innerContainer {
  position: relative;
}

.lightThemeLink:hover {
  text-decoration: none;
  color: var(--light-mode-surface-secondary-hover) !important;
}

.darkThemeLink:hover {
  text-decoration: none;
  color: var(--dark-mode-surface-secondary-hover) !important;
}

.flip {
  transform: rotateX(180deg);
  transition-duration: 1s;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.revertFlip {
  transform: rotateX(360deg);
  transition-duration: 1s;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* #contextmenu {
  z-index: 10 !important;
  box-shadow: unset;
}
#contextmenu,
.dwejEw {
  background-color: var(--dark-mode-surface) !important;
  background-image: unset !important;
}
#contextmenu > * {
  background-color: var(--dark-mode-surface) !important;
  color: var(--dark-mode-background-font-color) !important;
} */
::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(0, 0, 0, 0.03);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #737373;
}

canvas {
  padding: 0 0.5em;
  max-height: 100% !important;
  height: 100% !important;
  width: 100% !important;
}
